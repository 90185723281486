import { useState, useRef } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import css from "./style.module.css";
import fromLogo from "../img/mt-1330-logo.png";
import sendEmail from "./sendMessage";

export default function FirstBlock({ windowDimensions }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [burgerClick, setBurgerClick] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    sendEmail(formRef, e);
    setName("");
    setPhone("");
    setEmail("");
    setIsChecked(false);
  };

  const openBurgerMenu = () => {
    setBurgerClick(true);
  };

  const closeBurgerMenu = () => {
    setBurgerClick(false);
  };

  return (
    <div id="section1" className={css.wrapFirstBlock}>
      <header className={css.wrapHeader}>
        <div className={css.wrapLogo}>
          <img src={fromLogo} alt="Logo" />
          <p className={css.logoWrap}>TruckDispach</p>
        </div>
        {burgerClick ? (
          <div className={css.burgerMenuWrap}>
            <AiOutlineClose
              onClick={closeBurgerMenu}
              className={css.closeBurgerMenu}
            />
            <ul className={css.listStyle}>
              <li className={css.liForNav}>
                <a href="#section1" className={css.liForNav}>
                  Main
                </a>
              </li>
              <li className={css.liForNav}>
                <a href="#section2" className={css.liForNav}>
                  Services
                </a>
              </li>
              <li className={css.liForNav}>
                <a href="#section3" className={css.liForNav}>
                  About
                </a>
              </li>
              <li className={css.liForNav}>
                <a href="#section4" className={css.liForNav}>
                  Contact
                </a>
              </li>
              <li className={css.liForNav}>
                <Link to="/terms-and-conditions" className={css.liForNav}>
                  Terms and Conditions
                </Link>
              </li>
              <li className={css.liForNav}>
                <Link to="/privacy-policy" className={css.liForNav}>
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        ) : (
          !windowDimensions && (
            <RxHamburgerMenu
              onClick={openBurgerMenu}
              className={css.rxHamburgerMenu}
            />
          )
        )}
        {windowDimensions && (
          <nav className={css.navigation}>
            <ul className={css.navigationUL}>
              <li className={css.liForNav}>
                <a href="#section1" className={css.liForNav}>
                  Main
                </a>
              </li>
              <li className={css.liForNav}>
                <a href="#section2" className={css.liForNav}>
                  Services
                </a>
              </li>
              <li className={css.liForNav}>
                <a href="#section3" className={css.liForNav}>
                  About
                </a>
              </li>
              <li className={css.liForNav}>
                <a href="#section4" className={css.liForNav}>
                  Contact
                </a>
              </li>
              <li className={css.liForNav}>
                <Link to="/terms-and-conditions" className={css.liForNav}>
                  Terms and Conditions
                </Link>
              </li>
              <li className={css.liForNav}>
                <Link to="/privacy-policy" className={css.liForNav}>
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </nav>
        )}
        <a href="tel:+12677807627" className={css.nubberWrap}>
          +1(267) 780-76-27
        </a>
      </header>
      <div className={css.wrapformWithT}>
        <div className={css.smallWrap}>
          <form
            className={css.wrapFormSend}
            onSubmit={handleSubmit}
            ref={formRef}
          >
            <p className={css.pForm}>Contact us for free setup</p>
            <div className={css.inpWrapAll}>
              <div className={css.wrapInp}>
                <label className={css.labelForIn}>Full name</label>
                <input
                  className={css.inputForm}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  name="user_name"
                />
              </div>
              <div className={css.wrapInp}>
                <label className={css.labelForIn}>Phone number</label>
                <input
                  className={css.inputForm}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Phone"
                  name="Phone"
                />
              </div>
              <div className={css.wrapInp}>
                <label className={css.labelForIn}>Email</label>
                <input
                  className={css.inputForm}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  name="user_email"
                />
              </div>
            </div>
            <div className={css.checkboxContainer}>
              <input
                type="checkbox"
                id="termsCheckbox"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <label htmlFor="termsCheckbox" className={css.checkboxLabel}>
                I agree to the terms
              </label>
            </div>
            <p className={css.somePIn}>
              By checking this box, you agree to receive SMS text messages from
              TruckDispach. Reply STOP to opt-out at any time. Reply HELP for
              customer care contact information. Message and data rates may
              apply. Message frequency may vary.
            </p>
            <button type="submit" className={css.buttonRequest}>
              Send request
            </button>
          </form>
          <div className={css.wrapMainText}>
            <p className={css.dispServP}>Freight Dispatch Service</p>
            <a href="tel:+12677807627" className={css.nubberWrap}>
              +1(267) 780-76-27
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
